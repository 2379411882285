import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { HandleErrorService, IStandartResponse } from "src/app/services/handle-error.service";
import { EnvService } from "src/app/models/env";
import { catchError, Observable } from "rxjs";
import { TFilters, TRequestFilter, TSendRateCase, TStatusFilters, TTgNotificationSubscribeStatusGet, TUpdateTgNotificationSubscribeBody } from "../../../models/ApplicationData";

@Injectable({
    providedIn: 'root'
})
export class ApplicationsHttpService {

    constructor(
        private httpClient: HttpClient,
        private handleErrorService: HandleErrorService,
        private envService: EnvService
    ) {
    }

    public getApplications(page: number, subject: string = '', limit: number = 15, filters: TFilters = 'response_desc', unread: boolean = false, statuses: TStatusFilters = {
        open: true,
        closed: true,
        new: true,
        awaiting: true
    }): Observable<IStandartResponse<any>> {
        let filter: TRequestFilter = {
            status: { awaiting: false, closed: false, new: false, open: false }
        };

        if (filters !== null) {
            filter[filters] = true;
        }
        if (unread) {
            filter['unread'] = true;
        }

        for (const [key, value] of Object.entries(statuses)) {
            if (value !== false) {
                filter['status'][key] = 1;
            } else {
                filter['status'][key] = 0;
            }
        }

        return this.httpClient.post<any>
        (`${ this.envService.serverUrl }/v1/support-chat/list-cases`, {
            page,
            subject,
            limit,
            filter
        }, this.envService.httpOptions).pipe(catchError(err => this.handleErrorService.handleError(err)));
    }

    public getApplicationAdditionalData(caseId: number, page: number, fullData: boolean = false): Observable<IStandartResponse<any>> {
        return this.httpClient.get<any>
        (`${ this.envService.serverUrl }/v1/support-chat/get-case?case_id=${ caseId }&page=${ page }&case_info=${ fullData ? 1 : 0 }`, this.envService.httpOptions).pipe(catchError(err => this.handleErrorService.handleError(err)));
    }

    public createApplication(data: FormData): Observable<any> {
        return this.httpClient.post<any>
        (`${ this.envService.serverUrl }/v1/support-chat/create-case`, data, {
            ...this.envService.httpOptions,
            reportProgress: true,
            observe: 'events'
        }).pipe(catchError(err => this.handleErrorService.handleError(err)));
    }

    public createMessage(data: FormData): Observable<any> {
        return this.httpClient.post<any>
        (`${ this.envService.serverUrl }/v1/support-chat/post-message`, data, {
            ...this.envService.httpOptions,
            observe: 'response'
        }).pipe(catchError(err => this.handleErrorService.handleError(err)));
    }

    public setRating(data: TSendRateCase): Observable<IStandartResponse<[]>> {
        return this.httpClient.post<any>
        (`${ this.envService.serverUrl }/v1/support-chat/rate-case`, data, this.envService.httpOptions).pipe(catchError(err => this.handleErrorService.handleError(err)));
    }

    public updateApplication(data: FormData): Observable<any> {
        return this.httpClient.post<any>
        (`${ this.envService.serverUrl }/v1/support-chat/update-case`, data, {
            ...this.envService.httpOptions,
            reportProgress: true,
            observe: 'events'
        }).pipe(catchError(err => this.handleErrorService.handleError(err)));
    }

    public getNotifications(): Observable<any> {
        return this.httpClient.get<any>
        (`${ this.envService.serverUrl }/v1/support-chat/account-notifications`, this.envService.httpOptions).pipe(catchError(err => this.handleErrorService.handleError(err)));
    }

    public resetNotifications(caseId: number): Observable<any> {
        return this.httpClient.post<any>
        (`${ this.envService.serverUrl }/v1/support-chat/account-notifications`, { "case_id": caseId }, {
            ...this.envService.httpOptions,
            observe: 'response'
        }).pipe(catchError(err => this.handleErrorService.handleError(err)));
    }

    public getUserData(): Observable<any> {
        return this.httpClient.get<any>
        (`${ this.envService.serverUrl }/v1/support-chat/get-user-data`, this.envService.httpOptions).pipe(catchError(err => this.handleErrorService.handleError(err)));
    }

    public getTgNotificationSubscribeStatus(): Observable<any> {
        return this.httpClient.get<TTgNotificationSubscribeStatusGet>(
            `${ this.envService.serverUrl }/v1/support-chat/user-notifications`,
            this.envService.httpOptions
        ).pipe(catchError(err => this.handleErrorService.handleError(err)));
    }

    public updateSubscribeTgNotifications(isSubscribe: boolean, case_id?: number): Observable<any> {
        const body: TUpdateTgNotificationSubscribeBody = {
            subscribe: isSubscribe
        }
        if (!!case_id) {
            body.case_id = case_id;
        }
        return this.httpClient.post<any>(
            `${ this.envService.serverUrl}v1/support-chat/user-notifications`,
            body,
            this.envService.httpOptions
        ).pipe(catchError(err => this.handleErrorService.handleError(err)));;
    }
}

