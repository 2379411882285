import { Component, OnInit, signal, WritableSignal, OnDestroy } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { notRenderRoutes } from './snowflakes-background.config';

@Component({
    selector: 'new-year-snowflakes-background',
    templateUrl: './snowflakes-background.component.html',
    styleUrls: ['./snowflakes-background.component.css']
})
export class SnowflakesBackgroundComponent implements OnInit, OnDestroy {

    isShow: WritableSignal<boolean> = signal(false);

    private subscription: Subscription | null = null;

    constructor(private router: Router) {}

    ngOnInit(): void {
        this.subscription = this.router.events.subscribe((event) => {
            if (!(event instanceof NavigationEnd)) {
                return;
            }

            if (notRenderRoutes.find(route => event.url.startsWith(route))) {
                this.isShow.set(false);
                return;
            }

            this.isShow.set(true);
        });
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }
}