@if (isShow()) {
    <svg width="141" height="71" viewBox="0 0 141 71" fill="none" xmlns="http://www.w3.org/2000/svg" class="top-snowflake">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M46.5874 36.6129C47.6563 36.3265 48.7718 36.8775 49.194 37.9003L53.1289 47.4322C53.5932 48.5569 53.0578 49.845 51.9332 50.3093C50.8085 50.7736 49.5204 50.2382 49.0561 49.1135L45.8593 41.3697L37.4213 43.6307C36.246 43.9456 35.0379 43.2481 34.723 42.0728C34.4081 40.8975 35.1056 39.6895 36.2809 39.3746L46.5874 36.6129Z" fill="var(--snowflake-fill-color)"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M89.8421 38.5598C90.1325 37.4919 91.17 36.8052 92.2664 36.9551L102.484 38.3514C103.689 38.5161 104.533 39.627 104.368 40.8325C104.203 42.0381 103.092 42.8818 101.887 42.7171L93.5863 41.5827L91.294 50.0122C90.9747 51.1863 89.764 51.8793 88.5899 51.56C87.4158 51.2407 86.7228 50.0301 87.0421 48.8559L89.8421 38.5598Z" fill="var(--snowflake-fill-color)"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M16.3725 9.89696C15.5756 8.97747 15.675 7.58608 16.5945 6.78919L24.2376 0.16517L17.737 -6.3354C16.8767 -7.19577 16.8767 -8.59071 17.737 -9.45109C18.5974 -10.3115 19.9923 -10.3115 20.8527 -9.45109L29.0264 -1.27743C29.4588 -0.844985 29.6921 -0.252238 29.6703 0.358946C29.6485 0.97013 29.3736 1.54475 28.9114 1.94529L19.4803 10.1189C18.5608 10.9158 17.1694 10.8164 16.3725 9.89696Z" fill="var(--snowflake-fill-color)"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M113.306 1.83862C112.523 1.05613 112.443 -0.18538 113.118 -1.0625L119.405 -9.23615C120.147 -10.2006 121.53 -10.381 122.494 -9.63913C123.459 -8.89727 123.639 -7.51404 122.897 -6.54962L117.789 0.0907698L123.967 6.26784C124.827 7.12821 124.827 8.52315 123.967 9.38353C123.106 10.2439 121.711 10.2439 120.851 9.38353L113.306 1.83862Z" fill="var(--snowflake-fill-color)"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M100.184 -53.1021C101.589 -52.2909 102.07 -50.4943 101.259 -49.0894L43.1002 51.6445C42.289 53.0495 40.4925 53.5308 39.0875 52.7197C37.6825 51.9085 37.2011 50.112 38.0123 48.707L96.171 -52.0269C96.9822 -53.4318 98.7787 -53.9132 100.184 -53.1021Z" fill="var(--snowflake-fill-color)"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M53.6616 23.8528C54.9847 23.6657 56.2673 24.3952 56.7829 25.628L63.1876 40.9419C63.8136 42.4386 63.1077 44.1594 61.611 44.7853C60.1143 45.4113 58.3935 44.7054 57.7675 43.2087L52.238 29.9875L36.3017 32.2405C34.6954 32.4676 33.209 31.3495 32.9819 29.7432C32.7548 28.1368 33.8729 26.6505 35.4793 26.4234L53.6616 23.8528Z" fill="var(--snowflake-fill-color)"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M39.2839 -52.9031C40.6919 -53.709 42.4866 -53.221 43.2926 -51.813L101.076 49.1368C101.881 50.5448 101.393 52.3396 99.9854 53.1455C98.5774 53.9514 96.7827 53.4634 95.9767 52.0554L38.1938 -48.8944C37.3878 -50.3024 37.8759 -52.0972 39.2839 -52.9031Z" fill="var(--snowflake-fill-color)"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M82.3752 26.024C82.8793 24.7865 84.1551 24.0453 85.4799 24.2201L101.936 26.3917C103.545 26.604 104.677 28.0799 104.464 29.6883C104.252 31.2967 102.776 32.4285 101.168 32.2162L86.9601 30.3413L80.8876 45.2466C80.2754 46.749 78.5613 47.4708 77.0588 46.8587C75.5564 46.2466 74.8347 44.5324 75.4468 43.03L82.3752 26.024Z" fill="var(--snowflake-fill-color)"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M8.8125 0.280762C8.8125 -1.34157 10.1277 -2.65674 11.75 -2.65674L128.067 -2.65674C129.69 -2.65674 131.005 -1.34157 131.005 0.280762C131.005 1.9031 129.69 3.21826 128.067 3.21826L11.75 3.21826C10.1277 3.21826 8.8125 1.9031 8.8125 0.280762Z" fill="var(--snowflake-fill-color)"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M24.2837 16.9583C23.0595 15.8938 22.93 14.0384 23.9946 12.8141L35.0119 0.144186L25.1187 -13.0468C24.1453 -14.3446 24.4083 -16.1858 25.7062 -17.1593C27.0041 -18.1327 28.8453 -17.8696 29.8187 -16.5718L41.1361 -1.48193C41.9669 -0.374216 41.9113 1.16324 41.0027 2.2081L28.4279 16.6692C27.3633 17.8934 25.5079 18.0229 24.2837 16.9583Z" fill="var(--snowflake-fill-color)"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M98.7181 2.09089C97.8946 1.03861 97.885 -0.43689 98.6948 -1.49976L108.755 -14.7034C109.738 -15.9938 111.581 -16.2429 112.872 -15.2597C114.162 -14.2765 114.411 -12.4333 113.428 -11.1429L104.743 0.256422L114.662 12.9312C115.662 14.2088 115.437 16.055 114.159 17.0549C112.882 18.0547 111.035 17.8296 110.035 16.552L98.7181 2.09089Z" fill="var(--snowflake-fill-color)"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M40.6934 17.5516C40.1994 16.7579 40.1126 15.7764 40.4599 14.9084L46.3684 0.1371L41.6283 -15.8608C41.3902 -16.6644 41.5067 -17.5316 41.9486 -18.2438C42.3905 -18.9561 43.1156 -19.4457 43.9414 -19.5893L57.4406 -21.937L67.4589 -32.5446C68.0232 -33.1421 68.8121 -33.4759 69.634 -33.4648C70.4559 -33.4538 71.2355 -33.0989 71.7836 -32.4863L81.8707 -21.2125L98.6003 -17.0301C99.438 -16.8207 100.141 -16.2533 100.523 -15.4787C100.905 -14.7042 100.926 -13.8009 100.582 -13.009L94.7351 0.438324L99.4281 15.1041C99.7019 15.9597 99.5692 16.8935 99.0677 17.6389C98.5663 18.3843 97.7514 18.8593 96.8557 18.9282L81.9877 20.0719L73.3429 33.9035C72.8296 34.7249 71.9443 35.2404 70.9766 35.2815C70.0089 35.3226 69.0831 34.884 68.5019 34.1092L57.9828 20.0837L42.962 18.9282C42.0298 18.8565 41.1875 18.3454 40.6934 17.5516ZM47.3999 13.3772L59.7599 14.328C60.603 14.3929 61.3772 14.8179 61.8846 15.4943L70.6315 27.1569L77.7921 15.7C78.2866 14.9088 79.1275 14.3996 80.0578 14.328L92.7001 13.3555L88.8027 1.17607C88.5855 0.497429 88.6224 -0.237011 88.9066 -0.890465L93.8114 -12.1715L79.5706 -15.7317C79.0003 -15.8743 78.4859 -16.1847 78.0939 -16.6228L69.536 -26.1875L61.0414 -17.1933C60.6062 -16.7325 60.0336 -16.4248 59.4091 -16.3162L48.1985 -14.3665L52.2912 -0.553715C52.4789 0.0798569 52.4475 0.758217 52.2021 1.37175L47.3999 13.3772Z" fill="var(--snowflake-fill-color)"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M47.2721 0.332289C47.2525 -0.502182 47.7061 -1.27609 48.4438 -1.66664L57.8304 -6.63599L56.7126 -18.3729C56.635 -19.1873 57.0151 -19.9776 57.6996 -20.4255C58.3842 -20.8733 59.2606 -20.905 59.9757 -20.5077L70.1188 -14.8727L78.4322 -20.4149C79.1082 -20.8656 79.9775 -20.9076 80.6938 -20.5243C81.4102 -20.1409 81.8574 -19.3943 81.8574 -18.5818V-6.77243L92.5435 -1.71059C93.3139 -1.34569 93.8046 -0.569153 93.8035 0.283245C93.8024 1.13564 93.3097 1.91094 92.5385 2.27389L82.9653 6.77892L81.8439 16.8711C81.759 17.6353 81.2818 18.2999 80.5848 18.6247C79.8878 18.9495 79.072 18.8874 78.4322 18.4609L70.2231 12.9882L60.1278 19.7184C59.4189 20.191 58.501 20.2125 57.7707 19.7735C57.0405 19.3345 56.6288 18.5138 56.7136 17.666L57.8161 6.64074L48.5365 2.27389C47.7813 1.91847 47.2917 1.16676 47.2721 0.332289ZM54.4023 0.164494L61.1013 3.31697C61.9459 3.7144 62.4483 4.60087 62.3554 5.52962L61.5617 13.4668L69.0011 8.50723C69.7411 8.01388 70.7052 8.01388 71.4452 8.50723L77.8644 12.7867L78.7221 5.0671C78.8067 4.30582 79.2806 3.64311 79.9737 3.31697L86.4401 0.273926L78.7111 -3.38718C77.9417 -3.75165 77.4511 -4.52684 77.4511 -5.37823V-14.4652L71.4452 -10.4613C70.7588 -10.0037 69.8743 -9.9679 69.1532 -10.3685L61.4959 -14.6226L62.3564 -5.5871C62.441 -4.69944 61.9821 -3.84834 61.1941 -3.43113L54.4023 0.164494Z" fill="var(--snowflake-fill-color)"/>
    </svg>
        
    <svg width="70" height="141" viewBox="0 0 70 141" fill="none" xmlns="http://www.w3.org/2000/svg" class="left-snowflake">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M51.3632 88.0753C51.5936 89.27 50.8118 90.4253 49.617 90.6557L39.6859 92.5707L42.0653 101.451C42.3802 102.626 41.6827 103.834 40.5074 104.149C39.3321 104.464 38.1241 103.766 37.8092 102.591L34.8174 91.4257C34.6591 90.8349 34.7535 90.205 35.078 89.6866C35.4025 89.1682 35.9278 88.808 36.5283 88.6922L48.7828 86.3292C49.9775 86.0988 51.1328 86.8806 51.3632 88.0753Z" fill="var(--snowflake-fill-color)"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M41.5633 34.6943C42.7117 35.0964 43.3167 36.3534 42.9145 37.5018L39.5718 47.0475L48.4428 49.4599C49.617 49.7792 50.3099 50.9899 49.9906 52.164C49.6713 53.3381 48.4607 54.0311 47.2866 53.7118L36.1324 50.6784C35.5422 50.5179 35.0454 50.1194 34.7607 49.5781C34.476 49.0368 34.4291 48.4016 34.6312 47.8244L38.7559 36.0455C39.158 34.8971 40.415 34.2922 41.5633 34.6943Z" fill="var(--snowflake-fill-color)"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M-9.89745 16.3728C-8.97796 15.5759 -7.58657 15.6753 -6.78968 16.5947L-0.165658 24.2378L6.33491 17.7373C7.19528 16.8769 8.59023 16.8769 9.4506 17.7373C10.311 18.5976 10.311 19.9926 9.4506 20.853L1.27694 29.0266C0.844497 29.4591 0.25175 29.6923 -0.359434 29.6705C-0.970618 29.6487 -1.54524 29.3738 -1.94578 28.9117L-10.1194 19.4805C-10.9163 18.561 -10.8169 17.1696 -9.89745 16.3728Z" fill="var(--snowflake-fill-color)"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M-1.83862 113.306C-1.05613 112.523 0.18538 112.443 1.0625 113.118L9.23615 119.405C10.2006 120.147 10.381 121.53 9.63913 122.494C8.89727 123.459 7.51404 123.639 6.54962 122.897L-0.0907698 117.789L-6.26784 123.967C-7.12821 124.827 -8.52315 124.827 -9.38353 123.967C-10.2439 123.106 -10.2439 121.711 -9.38353 120.851L-1.83862 113.306Z" fill="var(--snowflake-fill-color)"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M53.1016 100.184C52.2904 101.589 50.4938 102.07 49.0889 101.259L-51.645 43.1001C-53.0499 42.2889 -53.5313 40.4924 -52.7202 39.0874C-51.909 37.6824 -50.1124 37.201 -48.7075 38.0122L52.0264 96.1709C53.4313 96.9821 53.9127 98.7786 53.1016 100.184Z" fill="var(--snowflake-fill-color)"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M48.0427 78.0036C48.5706 79.5376 47.755 81.2092 46.221 81.7371L30.3447 87.2009L32.317 103.571C32.5111 105.182 31.3627 106.645 29.752 106.839C28.1413 107.033 26.6783 105.885 26.4842 104.274L24.228 85.5471C24.0624 84.1724 24.8792 82.8687 26.1885 82.4181L44.3092 76.1819C45.8432 75.6539 47.5147 76.4695 48.0427 78.0036Z" fill="var(--snowflake-fill-color)"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M52.9026 39.2838C53.7085 40.6918 53.2205 42.4865 51.8125 43.2924L-49.1373 101.075C-50.5453 101.881 -52.3401 101.393 -53.146 99.9853C-53.9519 98.5773 -53.4639 96.7825 -52.0559 95.9766L48.894 38.1936C50.302 37.3877 52.0967 37.8758 52.9026 39.2838Z" fill="var(--snowflake-fill-color)"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M31.1908 32.4951C32.7821 32.8108 33.8161 34.3568 33.5004 35.9481L30.2327 52.4172L45.3717 58.9507C46.8612 59.5936 47.5476 61.3222 46.9048 62.8118C46.2619 64.3013 44.5333 64.9877 43.0438 64.3448L25.7254 56.8708C24.4541 56.3221 23.7386 54.9602 24.0081 53.602L27.7377 34.8047C28.0535 33.2134 29.5995 32.1793 31.1908 32.4951Z" fill="var(--snowflake-fill-color)"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M-0.280762 8.8125C1.34157 8.8125 2.65674 10.1277 2.65674 11.75V128.067C2.65674 129.69 1.34157 131.005 -0.280762 131.005C-1.9031 131.005 -3.21826 129.69 -3.21826 128.067V11.75C-3.21826 10.1277 -1.9031 8.8125 -0.280762 8.8125Z" fill="var(--snowflake-fill-color)"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M-16.9588 24.2839C-15.8943 23.0597 -14.0388 22.9303 -12.8146 23.9948L-0.144674 35.0122L13.0463 25.119C14.3441 24.1456 16.1854 24.4086 17.1588 25.7065C18.1322 27.0043 17.8691 28.8456 16.5713 29.819L1.48144 41.1363C0.373728 41.9671 -1.16373 41.9115 -2.20859 41.003L-16.6697 28.4281C-17.8939 27.3636 -18.0233 25.5082 -16.9588 24.2839Z" fill="var(--snowflake-fill-color)"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M-2.09138 98.718C-1.0391 97.8944 0.436401 97.8849 1.49928 98.6947L14.7029 108.755C15.9933 109.738 16.2424 111.581 15.2592 112.871C14.276 114.162 12.4328 114.411 11.1424 113.428L-0.25691 104.743L-12.9317 114.662C-14.2093 115.662 -16.0555 115.437 -17.0554 114.159C-18.0552 112.881 -17.8301 111.035 -16.5525 110.035L-2.09138 98.718Z" fill="var(--snowflake-fill-color)"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M-17.5516 40.6932C-16.7579 40.1991 -15.7764 40.1124 -14.9084 40.4596L-0.1371 46.3681L15.8608 41.628C16.6644 41.3899 17.5316 41.5065 18.2438 41.9483C18.9561 42.3902 19.4457 43.1154 19.5893 43.9412L21.937 57.4404L32.5446 67.4586C33.1421 68.023 33.4759 68.8119 33.4648 69.6338C33.4538 70.4557 33.0989 71.2353 32.4863 71.7834L21.2125 81.8705L17.0301 98.6001C16.8207 99.4378 16.2533 100.141 15.4787 100.523C14.7042 100.904 13.8009 100.926 13.009 100.582L-0.438324 94.7349L-15.1041 99.4279C-15.9597 99.7017 -16.8935 99.5689 -17.6389 99.0675C-18.3843 98.5661 -18.8593 97.7511 -18.9282 96.8554L-20.0719 81.9875L-33.9035 73.3427C-34.7249 72.8293 -35.2404 71.9441 -35.2815 70.9764C-35.3226 70.0086 -34.884 69.0829 -34.1092 68.5017L-20.0837 57.9826L-18.9282 42.9617C-18.8565 42.0296 -18.3454 41.1872 -17.5516 40.6932ZM-13.3772 47.3997L-14.328 59.7596C-14.3929 60.6027 -14.8179 61.377 -15.4943 61.8843L-27.1569 70.6313L-15.7 77.7918C-14.9088 78.2863 -14.3996 79.1273 -14.328 80.0575L-13.3555 92.6999L-1.17607 88.8025C-0.497429 88.5853 0.237011 88.6222 0.890465 88.9063L12.1715 93.8111L15.7317 79.5704C15.8743 79.0001 16.1847 78.4857 16.6228 78.0937L26.1875 69.5358L17.1933 61.0412C16.7325 60.606 16.4248 60.0334 16.3162 59.4089L14.3665 48.1982L0.553715 52.2909C-0.0798569 52.4786 -0.758217 52.4473 -1.37175 52.2018L-13.3772 47.3997Z" fill="var(--snowflake-fill-color)"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M-0.332533 47.2721C0.501938 47.2525 1.27585 47.7061 1.6664 48.4438L6.63575 57.8304L18.3727 56.7126C19.1871 56.635 19.9774 57.0151 20.4252 57.6996C20.8731 58.3842 20.9047 59.2606 20.5075 59.9757L14.8724 70.1188L20.4147 78.4322C20.8654 79.1082 20.9074 79.9775 20.524 80.6938C20.1406 81.4102 19.3941 81.8574 18.5816 81.8574H6.77219L1.71035 92.5435C1.34545 93.3139 0.568909 93.8046 -0.283489 93.8035C-1.13589 93.8024 -1.91118 93.3097 -2.27413 92.5385L-6.77916 82.9653L-16.8713 81.8439C-17.6355 81.759 -18.3002 81.2818 -18.625 80.5848C-18.9497 79.8878 -18.8876 79.072 -18.4611 78.4322L-12.9884 70.2231L-19.7186 60.1278C-20.1912 59.4189 -20.2127 58.501 -19.7737 57.7707C-19.3348 57.0405 -18.5141 56.6288 -17.6663 56.7136L-6.64098 57.8161L-2.27413 48.5365C-1.91872 47.7813 -1.167 47.2917 -0.332533 47.2721ZM-0.164738 54.4023L-3.31721 61.1013C-3.71464 61.9459 -4.60112 62.4483 -5.52986 62.3554L-13.467 61.5617L-8.50747 69.0011C-8.01412 69.7411 -8.01412 70.7052 -8.50747 71.4452L-12.7869 77.8644L-5.06735 78.7221C-4.30607 78.8067 -3.64336 79.2806 -3.31721 79.9737L-0.27417 86.4401L3.38694 78.7111C3.7514 77.9417 4.52659 77.4511 5.37798 77.4511H14.465L10.461 71.4452C10.0035 70.7588 9.96765 69.8743 10.3683 69.1532L14.6223 61.4959L5.58686 62.3564C4.69919 62.441 3.84809 61.9821 3.43089 61.1941L-0.164738 54.4023Z" fill="var(--snowflake-fill-color)"/>
    </svg>
}