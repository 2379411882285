import { IWheelEvent } from 'src/app/content/constructor/base-modal/base-modal.types';
import { ElementTypes } from '../enums/ElementTypes';
import { LanguageService } from '../translate/language.service';
import { ElementRef, Renderer2 } from "@angular/core";

export abstract class HelperFunctions {

    public static IsTouchPad(event: IWheelEvent): boolean {
        return event.wheelDeltaY ? event.wheelDeltaY === -3 * event.deltaY : event.deltaMode === 0;
    }

    public static randomString(length: number): string {
        var result           = [];
        var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for ( var i = 0; i < length; i++ ) {
            result.push(characters.charAt(Math.floor(Math.random() * charactersLength)));
        }
        return result.join('');
    }

    public static firstLetterToUppercase(srt: string): string {
        return srt[0].toUpperCase() + srt.slice(1);
    }

    public static parseJson<T>(srt: string, defaultResponse: T): T {
        try {
            return JSON.parse(srt);
        } catch (e) {
            return defaultResponse;
        }
    }

    public static isEmail(str: string): boolean {
        const EMAIL_REGEXP: RegExp = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/iu;
        return EMAIL_REGEXP.test(str.trim());
    }

    public static isMask(str: string): boolean {
        const MASK_REGEXP: RegExp = /^({{)(.*?)(}})$/ig;
        return MASK_REGEXP.test(str);
    }

    public static isSaveCombo($event: KeyboardEvent): boolean {
        return ($event.shiftKey && ($event.ctrlKey || $event.metaKey) && $event.code == 'KeyS');
    }

    public static camelize(str: string): string {
        return str.toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase());
    }

    public static extractTextNode(root: Node): Node {
        let iter: NodeIterator = document.createNodeIterator(root, NodeFilter.SHOW_TEXT);
        let textnode: Node;
        while (textnode = iter.nextNode()) {
            return textnode;
        }
    }

    public static getNewModalElements(elementType: string): boolean {
        let elemntTypesWithRigthMenu: string[] = [ElementTypes.CLOUD_STORAGE, ElementTypes.NOTE, ElementTypes.TASK, ElementTypes.IF, ElementTypes.START, ElementTypes.WAIT, ElementTypes.FIELD, ElementTypes.SUPERFIELD, ElementTypes.WEBHOOK];
        return elemntTypesWithRigthMenu.includes(elementType);
    }

    public static uniqueArr<T>(arr: T[]): T[] {
        let result: T[] = [];
        for (let str of arr) {
            if (!result.includes(str)) {
                result.push(str);
            }
        }
        return result;
    }

    public static getMonthLocalize(monthNumber: number): string {
        let month: string[] = LanguageService.translate('time.names.month.full').split(',');
        return month[monthNumber].substr(0, 3).toLowerCase();
    }

    public static isFramed(): boolean {
        let isFramed: boolean = false;
        try {
            isFramed = window != window.top || document != top.document || self.location != top.location;
        } catch (e) {
            isFramed = true;
        }
        return isFramed;
    }

    public static positionContent(renderer: Renderer2, contentElement: ElementRef, buttonElement: ElementRef, positionY?: string, positionX?: string): void {
        let content = contentElement.nativeElement;
        let buttonBox: DOMRect = buttonElement.nativeElement.getBoundingClientRect();
        let right: number;
        if (positionX === 'right') {
            right = window.innerWidth - buttonBox.x - content.getBoundingClientRect().width;
        } else if (positionX === 'left') {
            right = window.innerWidth - buttonBox.x - buttonBox.width;
        }
        let bottom: number;

        let contentPosition: number = buttonBox.y - content.offsetHeight - buttonBox.height;
        if (positionY === 'top' || contentPosition > 0) {
            bottom = window.innerHeight - buttonBox.y;
        } else if (positionY === 'bottom' || contentPosition < 0) {
            bottom = window.innerHeight - buttonBox.y - content.offsetHeight - buttonBox.height;
        }

        renderer.setStyle(contentElement.nativeElement, 'right', right + 'px');
        renderer.setStyle(contentElement.nativeElement, 'bottom', bottom + 'px');
        renderer.setStyle(contentElement.nativeElement, 'display', 'block');
    }

    public static getUrlTgBotNotifications(user_id: number, account_id: string, case_id?: number): string {
        let params = `${user_id}_${account_id}`;
        if (case_id) {
            params += `_${case_id}`;
        }
        params = window.btoa(params);
        return `https://t.me/sensei_notifications_support_bot?start=${params}`;
    }
}
