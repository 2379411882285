import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SnowflakesBackgroundComponent } from './snowflakes-background/snowflakes-background.component';



@NgModule({
  declarations: [
    SnowflakesBackgroundComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    SnowflakesBackgroundComponent
  ]
})
export class NewYearModule { }
